/* Estilos existentes */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App {
  width: 80%;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.month {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

#monthYear {
  font-size: 1.5em;
}

button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background: #0056b3;
}

#calendarDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.day-number, .inactive {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
}

.day-number.today {
  background: #007bff;
  color: #fff;
}

.inactive {
  color: #bbb;
}

form {
  margin: 20px 0;
}

form input, form button {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

form button {
  background: #28a745;
  color: #fff;
  cursor: pointer;
}

form button:hover {
  background: #218838;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

li button {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
}

li button:hover {
  background: #c82333;
}

p {
  text-align: center;
  font-size: 1.2em;
  color: #333;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .App {
      width: 95%;
      padding: 10px;
  }

  #monthYear {
      font-size: 1.2em;
  }

  button {
      padding: 5px;
  }

  #calendarDays {
      grid-template-columns: repeat(7, 1fr);
      gap: 2px;
  }

  .day-number, .inactive {
      padding: 5px;
      font-size: 0.8em;
  }

  form input, form button {
      padding: 8px;
  }

  li {
      flex-direction: column;
      align-items: flex-start;
  }

  li button {
      align-self: flex-end;
      margin-top: 5px;
  }
}
